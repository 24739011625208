// import React, { useState } from "react";
// import "./infra.css";
// import Footer from "./Components/Footer";
// import Navbar from "./Navbar";

// function Infra() {


//   const [selectedService, setSelectedService] = useState("");
//   const [showQueryForm, setShowQueryForm] = useState(false);
//   const [formValues, setFormValues] = useState({ name: "", phone_no: "", email: "", query: "" });

//   const handleImageClick = (serviceName) => {
//     if (showQueryForm && selectedService === serviceName) {
//       setShowQueryForm(false);
//     } else {
//       setSelectedService(serviceName);
//       setShowQueryForm(true);
//       setFormValues({ ...formValues, name: serviceName });
//     }
//   };

//   const handleChange = (e) => {
//     setFormValues({ ...formValues, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://highersystem-backend-1.onrender.com/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formValues),
//       });

//       if (response.ok) {
//         window.alert("Message has been sent! Thank you for visiting! Our team will contact you soon.");
//       } else {
//         const errorData = await response.json();
//         const errorMessage = errorData.message || "Failed to submit form";
//         throw new Error(errorMessage);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       window.alert(`Error: ${error.message}. Please try again later.`);
//     }
//   };


//   {
//     const [isHovered, setIsHovered] = useState(false);

//     const handleMouseEnter = () => {
//       setIsHovered(true);
//     };

//     const handleMouseLeave = () => {
//       setIsHovered(false);
//     };

//     const handleImageClick = (infradesc) => {
//       console.log(infradesc);
//     };
//   }
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   const handleCloseForm = () => {
//     setShowQueryForm(false);
//   };

//   return (   
//     <div style={{ overflow: 'hidden' }}>
//       <Navbar />
//       <div className="infra-image-container" >
//         <img src={require("./imple.jpg")} alt="img" className="infra-background-image" />
//         <div className="infra-overlay-text">IT Infrastructure Solutions</div>
//       </div>

//       <div className="infra-parrent">
//         {/***********************    LINE--1   ************************************/}
//         <div className="infra-parrent-1" >

//           <div className="infra-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Cloud Computing")} src={require('./cloud-computing.svg')} alt="img" />
//               <p className="infra-p1">Cloud Computing</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Cloud computing is a technology that delivers computing services over the internet.
//                 It allows users to access servers, storage, and applications without needing to own physical infrastructure.
//                 This model enables businesses to scale resources, reduce costs, and innovate rapidly.</div>
//             )}
//           </div>

//           <div className="infra-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Network & Wifi")} src={require('./wifi.svg')} alt="img" />
//               <p className="infra-p1">Network & Wifi</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">A network is a collection of computers and other devices connected together to share resources and information. WiFi, a wireless networking technology, enables devices to connect to a network without the need for physical cables, using radio waves for communication.</div>
//             )}
//           </div>

//           <div className="infra-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Virtualization")} src={require('./enterprisre.svg')} alt="img" />
//               <p className="infra-p1">Virtualization</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Virtualization is the process of creating a virtual version of a physical resource,
//                 such as a server, operating system, storage device, or network. It allows multiple virtual instances to run on a
//                 single physical machine, optimizing resource utilization and improving scalability and flexibility in IT infrastructure
//                 management.</div>)}
//           </div>

//         </div>

//         {/*********************   LINE--2  ****************************/}
//         <div className="infra-parrent-2" >

//           <div className="infra-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Privileged Identity Management")} src={require('./identity-card.svg')} alt="img" />
//               <p className="infra-p2">Privileged Identity<br />Management</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Privileged Identity Management (PIM) is a security practice that manages and controls access to
//                 privileged accounts within an organization's IT environment. It ensures accountability, restricts unauthorized access, and
//                 mitigates the risk of data breaches by monitoring and managing privileged user activities.</div>
//             )}
//           </div>

//           <div className="infra-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Privileged Access Management")} src={require('./copyright.svg')} alt="img" />
//               <p className="infra-p2">Privileged Access <br />Management</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Privileged Access Management (PAM) is a cybersecurity strategy that secures, controls,
//                 and monitors privileged access to critical systems and data within an organization. It reduces the risk of insider
//                 threats, external attacks, and data breaches by enforcing least privilege principles and implementing robust authentication
//                 and authorization mechanisms.</div>
//             )}
//           </div>

//           <div className="infra-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Security Systems")} src={require('./cctv-camera.svg')} alt="img" />
//               <p className="infra-p2">Security Systems</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">A security system comprises measures, technologies, and policies designed to
//                 safeguard digital assets, data, and infrastructure from unauthorized access, breaches, or damage. It
//                 typically includes components such as firewalls, encryption, access controls, intrusion detection systems,
//                 and security protocols to mitigate risks and protect against cyber threats.</div>
//             )}
//           </div>

//         </div>

//         {/***********************    LINE--3 ************************************/}
//         <div className="infra-parrent-3" >
//           <div className="DS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Data Security")} src={require('./doucment.svg')} alt="img" />
//               <p className="infra-p3">Data Security</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Data security involves implementing measures to protect digital information
//                 from unauthorized access, alteration, or destruction, ensuring confidentiality, integrity, and availability
//                 of data. It encompasses encryption, access controls, regular backups, and security protocols to safeguard sensitive
//                 information from cyber threats and breaches.</div>
//             )}
//           </div>

//           <div className="FAGS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Firewall and Gateway Security")} src={require('./firewall (1).svg')} alt="img" />
//               <p className="infra-p3">Firewall and <br />Gateway Security</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Firewall and gateway security involves deploying hardware or software barriers to monitor and
//                 control incoming and outgoing network traffic, preventing unauthorized access and protecting against cyber threats. It serves
//                 as a barrier between internal networks and external sources, filtering data and enforcing security policies to safeguard against
//                 malicious activities.</div>
//             )}
//           </div>

//           <div className="SABS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Storage and Backup Solutions")} src={require('./doucment.svg')} alt="img" />
//               <p className="infra-p3">Storage and Backup<br />Solutions</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Storage and backup solutions involve implementing technologies and processes to securely
//                 store and replicate data, ensuring its availability and integrity in case of system failures or disasters. These solutions
//                 utilize storage systems, data replication, and backup software to protect against data loss and enable efficient data recovery procedures.</div>
//             )}
//           </div>

//         </div>

//         {/***********************    LINE--4***************************************/}
//         <div className="infra-parrent-4">
//           <div className="SIEM" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("Security Information & Event Management")} src={require('./content-management.svg')} alt="img" />
//               <p className="infra-p4">Security Information <br />& Event Management</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">Security Information and Event Management (SIEM) is a software solution that provides real-time analysis of
//                 security alerts generated by network hardware and applications. It correlates and aggregates security events, offering insights into potential
//                 security threats and facilitating proactive response measures.</div>
//             )}
//           </div>

//           <div className="IMS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//             <div>
//               <img onClick={() => handleImageClick("IT Managed Services")} src={require('./modular.svg')} alt="img" />
//               <p className="infra-p4">IT Managed Services</p>
//             </div>
//             {isHovered && (
//               <div className="infradesc">IT Managed Services involve outsourcing various IT functions to a third-party
//                 provider, who assumes responsibility for monitoring, managing, and maintaining an organization's IT infrastructure
//                 and systems. This approach enhances operational efficiency, reduces costs, and ensures access to specialized expertise
//                 for IT support and management.</div>
//             )}
//           </div>
//         </div>
//       </div>

//       {/*******    FOOTER    **********/}
//       <Footer />
//       <div id="box"></div>
//       <div id="play"></div>
//       <div id="play1" style={{ position: 'absolute' }}></div>
//       <div id="play"></div>
//       <div id="play"></div>
//       <div id="play"></div>
//       <div id="play"></div>

// {
//         showQueryForm && (
//           <div className="infra-form">
//             <form onSubmit={handleSubmit}>
//               <button className="infra-f2" onClick={handleCloseForm}>
//                 <img src={require('./remove.svg')} alt="img" /></button>
//               <div className='infra-q'>
//                 <h2>Let Me Know More About</h2>
//               </div>

//               <div className='infra-label'>
//                 <label htmlFor="name">Service Name</label>
//                 <input type="text" name="name" value={formValues.name} onChange={handleChange} readOnly />
//                 <label htmlFor="fullName">Full Name</label>
//                 <input type="text" id="fullName" name="fullName" value={formValues.fullName} onChange={handleChange} required />
//                 <label htmlFor="email">E-mail</label>
//                 <input type="email" name="email" placeholder="Email" value={formValues.email} onChange={handleChange} required />
//                 <label htmlFor="Phoneno">Phone No</label>
//                 <input type="tel" name="phone_no" placeholder="9876543210" value={formValues.number} onChange={handleChange} required />
//                 <label htmlFor="query">Query</label>
//                 <textarea style={{ width: '340px', height: '150px' }} name="query" placeholder="Your Query" value={formValues.query} onChange={handleChange} required />
//               </div>
//               <button className='submit-infra' type="submit">Submit</button>
//             </form>
//           </div>
//         )
//       }
//     </div >

//   ); 
// }
// export default Infra;



import React, { useState } from "react";
import "./infra.css";
import Footer from "./Components/Footer";
import Navbar from "./Navbar";

function Infra() {
  const [selectedService, setSelectedService] = useState("");
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [formValues, setFormValues] = useState({ name: "", phone_no: "", email: "", query: "" });
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleImageClick = (serviceName) => {
    if (showQueryForm && selectedService === serviceName) {
      setShowQueryForm(false);
    } else {
      setSelectedService(serviceName);
      setShowQueryForm(true);
      setFormValues({ ...formValues, name: serviceName });
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://highersystem-backend-1.onrender.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      if (response.ok) {
        window.alert("Message has been sent! Thank you for visiting! Our team will contact you soon.");
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || "Failed to submit form";
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      window.alert(`Error: ${error.message}. Please try again later.`);
    }
  };

  {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleImageClick = (infradesc) => {
      console.log(infradesc);
    };
  }

  const handleMoreClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowFullDescription(false);
  };

  const handleCloseForm = () => {
    setShowQueryForm(false);
  };
  //Line 1
  const shortDescription = "Cloud computing is revolutionizing the way businesses operate, offering unparalleled flexibility, scalability, and efficiency.";
  const fullDescription = "Cloud computing is revolutionizing the way businesses operate, offering unparalleled flexibility, scalability, and efficiency. By leveraging the power of the cloud, businesses can reduce costs, enhance collaboration, and access critical data and applications from anywhere in the world. At Higher India Pvt. Ltd., we specialize in providing top-tier cloud computing solutions that align with your business goals and drive innovation. Our consultants work closely with your team to develop a customized cloud strategy that aligns with your business objectives. At Higher India, we pride ourselves on delivering cloud solutions that are tailored to your unique business needs. We’ve helped businesses across various industries transform their operations with cloud technology. We partner with leading cloud providers like AWS Authorities to bring you the best solutions available. Our strong relationships with the providers ensure that you receive the highest level of service and support. Our cloud computing solutions are designed to meet the needs of a wide range of industries, including: Finance, Healthcare, Retail, Manufacturing, Education, Government.";
  const shortDescription1 = "Higher India Pvt Ltd specializes in delivering comprehensive networking solutions tailored to your specific needs. "
  const fullDescription1 = "Higher India Pvt Ltd specializes in delivering comprehensive networking solutions tailored to your specific needs. From the initial design and implementation of your network infrastructure to ongoing optimization, troubleshooting, and management, we provide expert guidance and support every step of the way. Our team is dedicated to ensuring that your network operates at peak performance, enabling seamless communication, efficient data sharing, and uninterrupted access to essential online resources. With our expertise in network design, WiFi optimization, troubleshooting, and management, you can trust us to build and maintain a network that empowers your business and drives success in the digital age."
  const shortDescription2 = "Virtualization is the process of creating virtual versions of physical hardware, including servers, storage devices, and networks."
  const fullDescription2 = "Virtualization is the process of creating virtual versions of physical hardware, including servers, storage devices, and networks. By abstracting hardware through software, virtualization allows multiple virtual machines to run on a single physical device, optimizing resource use, and providing greater flexibility and scalability. At Higher India Pvt. Ltd., we bring extensive expertise in virtualization technologies to help you optimize your IT infrastructure. Our solutions are designed to reduce costs, improve efficiency, and enhance flexibility, enabling your business to adapt to changing demands. We partner with industry leaders like VMware, Microsoft, Cisco, Checkpoint, Aruba and TP -link to deliver cutting-edge virtualization solutions that meet your unique needs. Our virtualization services are designed to benefit a wide range of industries, including: Finance, Healthcare, Retail, Manufacturing, Education, Government. Virtualization offers numerous benefits, including reduced hardware costs, improved resource utilization, enhanced scalability, and simplified management."
  //Line 2
  const shortDescription3 = "Privileged Identity Management (PIM) is essential for safeguarding the identities that have elevated access within your organization. "
  const fullDescription3 = "Privileged Identity Management (PIM) is essential for safeguarding the identities that have elevated access within your organization. These privileged identities, such as administrators or superusers, are often targeted by cybercriminals due to their broad access to critical systems and data. Our PIM services focus on managing, monitoring, and securing these high-risk identities to prevent unauthorized access and potential security breaches."
  const shortDescription4 = "Privileged Access Management (PAM) is a crucial component of any robust security strategy"
  const fullDescription4 = "Privileged Access Management (PAM) is a crucial component of any robust security strategy. PAM focuses on controlling and monitoring access to critical systems and sensitive data by privileged accounts. Our PAM services provide comprehensive solutions to ensure that privileged access is tightly managed, minimizing the risk of unauthorized access and potential breaches."
  const shortDescription5 = "A comprehensive security system is essential for protecting your organization from a wide range of threats, both physical and digital. "
  const fullDescription5 = "A comprehensive security system is essential for protecting your organization from a wide range of threats, both physical and digital. It integrates various technologies and strategies, such as surveillance cameras, access control systems, alarm systems, and cybersecurity measures, to provide a multi-layered defense. These systems work together to monitor and secure your premises, detect unauthorized access, and safeguard critical data. With features like real-time monitoring, automated alerts, and advanced threat detection, a robust security system ensures that your assets, employees, and sensitive information remain protected from potential risks. Investing in a reliable security system not only enhances safety but also provides peace of mind and business continuity."
  //Line3
  const shortDescription6 = "Data security is a critical aspect of protecting your organization's sensitive information from unauthorized access, breaches, and cyber threats. "
  const fullDescription6 = "Data security is a critical aspect of protecting your organization's sensitive information from unauthorized access, breaches, and cyber threats. It involves implementing robust measures such as encryption, access controls, and multi-factor authentication to safeguard data throughout its lifecycle—whether in storage, transit, or during processing. By preventing unauthorized access, data security solutions help maintain the confidentiality, integrity, and availability of your information. These solutions also ensure compliance with industry regulations, helping you avoid costly fines and reputation damage. With comprehensive data security in place, you can confidently manage your business while protecting valuable assets from evolving cyber risks."
  const shortDescription7 = "Firewall security services are essential for safeguarding your organization's digital assets from cyber threats. "
  const fullDescription7 = "Firewall security services are essential for safeguarding your organization's digital assets from cyber threats. These services act as a protective barrier between your internal network and external entities, filtering incoming and outgoing traffic based on predefined security rules. By identifying and blocking malicious activities such as hacking attempts, malware, and unauthorized access, firewall solutions ensure your sensitive data remains secure. With advanced features like intrusion prevention, real-time monitoring, and customizable policies, firewall security services not only enhance the protection of your network but also ensure compliance with industry standards, reducing the risk of data breaches and cyberattacks."
  const shortDescription8 = "Storage and backup solutions are essential for managing and safeguarding your business’s critical data. "
  const fullDescription8 = "Storage and backup solutions are essential for managing and safeguarding your business’s critical data. Storage solutions ensure that your data is organized, accessible, and scalable, while backup solutions protect your data from loss or corruption by creating copies that can be restored in case of an emergency. Our enterprise storage solutions provide scalable and secure data storage options that grow with your business. Whether you need on-premises storage, cloud storage, or a hybrid solution, we design and implement systems that meet your unique needs."
  //Line4
  const shortDescription9 = "In today's complex digital landscape, safeguarding your business against cyber threats is more critical than ever. "
  const fullDescription9 = "In today's complex digital landscape, safeguarding your business against cyber threats is more critical than ever. Our Security Information and Event Management (SIEM) services offer a comprehensive approach to monitoring, detecting, and responding to security incidents in real time. SIEM, combines both security information management(SIM) and security event management(SEM) into one security management system.  SIEM technology collects event log data from a range of sources, identify activity that deviates from the norms with real-time analysis, and takes appropriate action."
  const shortDescription10 = "At Higher India Pvt Ltd, we offer a comprehensive suite of IT Managed Services designed to ensure your business operations run smoothly and efficiently. Our services include:"
  const fullDescription10 = "Annual Maintenance Contracts (AMCs)--We provide reliable AMC services to keep your IT infrastructure in optimal condition. Our proactive approach ensures regular maintenance, minimizing downtime and preventing potential issues before they impact your business.Facility Management Services (FMS)--Our FMS solutions cover end-to-end management of IT facilities, including hardware, software, and network management. We ensure that your IT environment is always operational, allowing your business to focus on its core activities."

  return (
    <div style={{ overflow: 'hidden' }}>
      <Navbar />
      <div className="infra-image-container" >
        <img src={require("./imple.jpg")} alt="img" className="infra-background-image" />
        <div className="infra-overlay-text">IT Infrastructure Solutions</div>
      </div>

      <div className="infra-parrent">
        {/***********************    LINE--1   ************************************/}
        <div className="infra-parrent-1" >

          <div className="infra-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img
                onClick={() => handleImageClick('Cloud Computing')} src={require('./cloud-computing.svg')} alt="img"
              />
              <p className="infra-p1">Cloud Computing</p>
            </div>

            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription : shortDescription}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="infra-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Network & Wifi")} src={require('./wifi.svg')} alt="img" />
              <p className="infra-p1">Network & Wifi</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription1 : shortDescription1}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="infra-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Virtualization")} src={require('./enterprisre.svg')} alt="img" />
              <p className="infra-p1">Virtualization</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription2 : shortDescription2}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

        </div>

        {/*********************   LINE--2  ****************************/}
        <div className="infra-parrent-2" >

          <div className="infra-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Privileged Identity Management")} src={require('./identity-card.svg')} alt="img" />
              <p className="infra-p2">Privileged Identity<br />Management</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription3 : shortDescription3}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="infra-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Privileged Access Management")} src={require('./copyright.svg')} alt="img" />
              <p className="infra-p2">Privileged Access <br />Management</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription4 : shortDescription4}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="infra-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Security Systems")} src={require('./cctv-camera.svg')} alt="img" />
              <p className="infra-p2">Security Systems</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription5 : shortDescription5}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

        </div>

        {/***********************    LINE--3 ************************************/}
        <div className="infra-parrent-3" >
          <div className="DS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Data Security")} src={require('./doucment.svg')} alt="img" />
              <p className="infra-p3">Data Security</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription6 : shortDescription6}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="FAGS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Firewall and Gateway Security")} src={require('./firewall (1).svg')} alt="img" />
              <p className="infra-p3">Firewall and <br />Gateway Security</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription7 : shortDescription7}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="SABS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Storage and Backup Solutions")} src={require('./doucment.svg')} alt="img" />
              <p className="infra-p3">Storage and Backup<br />Solutions</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription8 : shortDescription8}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

        </div>

        {/***********************    LINE--4***************************************/}
        <div className="infra-parrent-4">
          <div className="SIEM" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Security Information & Event Management")} src={require('./content-management.svg')} alt="img" />
              <p className="infra-p4">Security Information <br />& Event Management</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription9 : shortDescription9}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="IMS" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("IT Managed Services")} src={require('./modular.svg')} alt="img" />
              <p className="infra-p4">IT Managed Services</p>
            </div>
            {isHovered && (
              <div className="infradesc">
                {showFullDescription ? fullDescription10 : shortDescription10}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*******    FOOTER    **********/}
      <Footer />
      <div id="box"></div>
      <div id="play"></div>
      <div id="play1" style={{ position: 'absolute' }}></div>
      <div id="play"></div>
      <div id="play"></div>
      <div id="play"></div>
      <div id="play"></div>

      {
        showQueryForm && (
          <div className="infra-form">
            <form onSubmit={handleSubmit}>
              <button className="infra-f2" onClick={handleCloseForm}>
                <img src={require('./remove.svg')} alt="img" /></button>
              <div className='infra-q'>
                <h2>Let Me Know More About</h2>
              </div>

              <div className='infra-label'>
                <label htmlFor="name">Service Name</label>
                <input type="text" name="name" value={formValues.name} onChange={handleChange} readOnly />
                <label htmlFor="fullName">Full Name</label>
                <input type="text" id="fullName" name="fullName" value={formValues.fullName} onChange={handleChange} required />
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" placeholder="Email" value={formValues.email} onChange={handleChange} required />
                <label htmlFor="Phoneno">Phone No</label>
                <input type="tel" name="phone_no" placeholder="9876543210" value={formValues.number} onChange={handleChange} required />
                <label htmlFor="query">Query</label>
                <textarea style={{ width: '340px', height: '150px' }} name="query" placeholder="Your Query" value={formValues.query} onChange={handleChange} required />
              </div>
              <button className='submit-infra' type="submit">Submit</button>
            </form>
          </div>
        )
      }
    </div >

  );
}
export default Infra;
import React, { useState } from "react";
import "./Business.css";
import Footer from "./Components/Footer";
import Navbar from "./Navbar";



function Business() {
  const [selectedService, setSelectedService] = useState("");
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [formValues, setFormValues] = useState({ name: "", PhoneNo: "", email: "", query: "" });

  const handleImageClick = (serviceName) => {
    if (showQueryForm && selectedService === serviceName) {
      setShowQueryForm(false);
    } else {
      setSelectedService(serviceName);
      setShowQueryForm(true);
      setFormValues({ ...formValues, name: serviceName });
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('FORM VALUES:', formValues);
    try {
      const response = await fetch("", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      if (response.ok) {
        window.alert("Message has been sent!");
        window.alert("Thank you for visiting! Our team will contact you soon.");
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error:", error);
      window.alert("Failed to submit form. Please try again later.");
    }
  };

  /**************************************************************************** */
  {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleImageClick = (busdesc) => {
      console.log(busdesc);
    };
  }
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowFullDescription(false);
  };
  /*******************************************************************************/

  const handleCloseForm = () => {
    setShowQueryForm(false);
  };

  const handleMoreClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  //Line 1
  const shortDescription = "Our Document Management System (DMS) delivered by our team provides a complete solution looking for document optimization based on document- related operations"
  const fullDescription = "Our Document Management System (DMS) delivered by our team provides a complete solution looking for document optimization based on document- related operations. Our DMS makes it possible to save, retrieve, and manage electronic documents effectively, which lowers the need for paper-based solutions and boosts output. With capabilities like version control, automated workflows, and document indexing, our system enables businesses to efficiently and securely store and retrieve information. Permissions that can be customized guarantee data integrity and adherence to legal standards, and smooth connection with current systems improves workflow effectiveness. Delivering cutting-edge DMS solutions that enhance document management and promote corporate success is our mission at Higher India."
  const shortDescription1 = "The Content Management System (CMS) delivered by us website-based Content Management System simplifies content generation, organization, and dissemination."
  const fullDescription1 = "The Content Management System (CMS) delivered by us website-based Content Management System simplifies content generation, organization, and dissemination. With the help of our CMS, customers can easily create, update, and manage digital material thanks to its user-friendly features. Our system guarantees effective content collaboration and approval procedures with features like version control, workflow automation, and customizable templates. Furthermore, our CMS easily interfaces with other corporate systems, enabling centralized management of various content kinds across a variety of channels. The content management system helps businesses to effectively engage their audience and maintain a dynamic online presence, whether it is through the management of papers, multimedia assets, or website content."
  const shortDescription2 = "Higher India have designed a Human Resource Management System (HRMS) solution that is painstakingly designed to transform how businesses handle their people resources."
  const fullDescription2 = "Higher India have designed a Human Resource Management System (HRMS) solution that is painstakingly designed to transform how businesses handle their people resources. Our HRMS system, which was created as a flagship product, offers an extensive set of tools and features to simplify and maximize all facets of HR operations. Our solution unifies all HR operations into a single platform, streamlining administrative work and improving speed and accuracy. This includes hiring and onboarding as well as payroll processing, benefits administration, and performance monitoring. Our HRMS gives HR managers the tools they need to make data-driven choices, improve employee engagement, and propel organizational success."
  //Line 2
  const shortDescription3 = "Higher India Company offers unique Asset Management System Solutions designed to revolutionize the management of digital and physical assets for businesses."
  const fullDescription3 = "Higher India Company offers unique Asset Management System Solutions designed to revolutionize the management of digital and physical assets for businesses. Our cutting-edge product enables comprehensive tracking, monitoring, and optimization of asset lifecycles for enhanced performance and cost-efficiency. With robust capabilities including utilization optimization, depreciation management, maintenance scheduling, and real-time tracking, our solutions empower organizations to make informed decisions and maximize asset value. Boost productivity, accelerate corporate development, and streamline processes with our Asset Management System Solutions. Committed to quality and leveraging cutting-edge technology, Higher India is your trusted partner for innovative asset management."
  const shortDescription4 = "Our core product, Inventory Management Solutions, completely transforms how businesses handle their inventory procedures"
  const fullDescription4 = "Our core product, Inventory Management Solutions, completely transforms how businesses handle their inventory procedures. Our Inventory Management Solutions provide unmatched efficiency and control over supply chain operations because to painstaking attention to detail and a thorough grasp of industry needs. Our solutions optimize stock replenishment, reduce procurement procedures, and give real-time visibility into inventory levels while seamlessly integrating with current systems.Our inventory management solutions serve a wide range of industry verticals, from startups to major corporations, enabling businesses to remain flexible, competitive, and ahead of market trends."
  const shortDescription5 = "With specialized industry knowledge and a strong network of partners, we empower our customers to stay ahead in their respective fields"
  const fullDescription5 = "With specialized industry knowledge and a strong network of partners, we empower our customers to stay ahead in their respective fields. Our comprehensive professional services and support teams guide customers through every stage of their Digital Transformation journey. By bridging the gap between digital vision and reality, we enable accelerated transformation across platforms, backed by strategic guidance, operational support, and training. Our agile and adaptive approach ensures we meet consumer demands effectively. With an innovative, goal-centric team, we're committed to developing, deploying, supporting, and evolving products and services to drive success."
  //Line 3
  const shortDescription6 = "At Higher India, we take great satisfaction in our ability to create innovative custom workflow solutions and enterprise-level software solutions that are suited to the changing demands of contemporary businesses"
  const fullDescription6 = "At Higher India, we take great satisfaction in our ability to create innovative custom workflow solutions and enterprise-level software solutions that are suited to the changing demands of contemporary businesses. Our software product suite has been carefully designed to tackle the intricate problems that businesses in a range of sectors encounter. Our Enterprise-Level Software Solutions provide a wide range of modules and features that are intended to simplify essential company operations, with a particular focus on improving operational efficiency, productivity, and agility"
  
  return (
    <div style={{ overflow: 'hidden' }}>
      <Navbar />
      <div className="bus-image-container" >
        <img src={require("./imple.jpg")} alt="img" className="bus-background-image" />
        <div className="bus-overlay-text">Business Solution</div>
      </div>

      {/***********************    PARRENT   ************************************/}
      <div className="Bus-parrent">
        <div className="bus-parrent-1" >

          <div className="bus-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Document Management System ")} src={require('./doucment.svg')} alt="img" />
              <p className="bus-p1">Document Management System </p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription : shortDescription}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="bus-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Content Management System")} src={require('./content-management.svg')} alt="img" />
              <p className="bus-p1">Content Management System</p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription1 : shortDescription1}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="bus-child-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Human Resource Management System ")} src={require('./human resource.svg')} alt="img" />
              <p className="bus-p1">Human Resource Management System </p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription2 : shortDescription2}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>
        </div >

        { /*****************    PARRENT ---1     ********************** */}
        <div className="bus-parrent-2" >

          <div className="bus-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img style={{ marginright: '2%' }} onClick={() => handleImageClick("Asset Management System")} src={require('./assets (2).svg')} alt="img" />
              <p className="bus-p2">Asset Management System</p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription3 : shortDescription3}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="bus-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Inventory Management System")} src={require('./inventory.svg')} alt="img" />
              <p className="bus-p2">Inventory Management System</p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription4 : shortDescription4}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>

          <div className="bus-child-2" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Digital Transformation and E-Learning ")} src={require('./elearning.svg')} alt="img" />
              <p className="bus-p2">Digital Transformation and E-Learning </p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription5 : shortDescription5}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>
        </div>


        {/*****************     PARRENT-3        *****************/}
        <div className="bus-parrent-3">
          <div className="bus-child-3" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
              <img onClick={() => handleImageClick("Enterprise-Level Software Solutions")} src={require('./enterprisre.svg')} alt="img" />
              <p className="bus-p3">Enterprise-Level Software Solutions</p>
            </div>
            {isHovered && (
              <div className="busdesc">
                {showFullDescription ? fullDescription6 : shortDescription6}
                <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                  {showFullDescription ? ' Show Less' : '... More'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/************      FOOTER HEADING       ************/}

      <Footer />
      <div id="box"></div>
      <div id="play"></div>
      <div id="play1" style={{ position: 'absolute' }}></div>
      <div id="play"></div>
      <div id="play"></div>
      <div id="play"></div>
      <div id="play"></div>




      {/**********************    QUERY FORM    *******************************/}
      {
        showQueryForm && (
          <div className="business-form">
            <form onSubmit={handleSubmit}>
              <button className="business-f2" onClick={handleCloseForm}>
                <img src={require('./remove.svg')} alt="img" /></button>
              <div className='bus-query'>
                <h2>Let Me Know More About</h2>
              </div>

              <div className='bus-label'>
                <label htmlFor="name">Service Name</label>
                <input type="text" name="name" value={formValues.name} onChange={handleChange} readOnly />
                <label htmlFor="fullName">Full Name</label>
                <input type="text" id="fullName" name="fullName" value={formValues.fullName} onChange={handleChange} required />
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" placeholder="Email" value={formValues.email} onChange={handleChange} required />
                <label htmlFor="Phoneno">Phone No</label>
                <input type="tel" name="Phoneno" placeholder="9876543210" value={formValues.number} onChange={handleChange} required />
                <label htmlFor="query">Query</label>
                <textarea style={{ width: '340px', height: '150px' }} name="query" placeholder="Your Query" value={formValues.query} onChange={handleChange} required />
              </div>
              <button className='submit-bus' type="submit">Submit</button>
            </form>
          </div>
        )
      }
    </div >
  );
}
export default Business;

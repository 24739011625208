import React, { useEffect, useState } from "react";
import "./Implementation.css";
import Footer from "./Components/Footer";
import Navbar from "./Navbar";

function Implementation() {
  const [selectedService, setSelectedService] = useState("");
  const [showQueryForm, setShowQueryForm] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [formValues, setFormValues] = useState({ name: "", phone_no: "", email: "", query: "" });
  const [isMobile, setIsMobile] = useState(false);

  /**************TOUCH EVENT*******************/
  useEffect(() => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    setIsMobile(isTouchDevice);
  }, []);

  /**************   TOUCH EVENT**************/


  function closeForm() {
    document.querySelector('.query-form').style.left = '200%';
  }

  /************HANDLE TOUCH EVENT **************/
  const handleTouchStart = () => {
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    setIsHovered(false);
  };
  /**************************END HERE******************** */

  const handleImageClick = (serviceName) => {
    if (showQueryForm && selectedService === serviceName) {
      setShowQueryForm(false);
    } else {
      setSelectedService(serviceName);
      setShowQueryForm(true);
      setFormValues({ ...formValues, name: serviceName });
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://highersystem-backend-1.onrender.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      if (response.ok) {
        window.alert("Message has been sent!");
        window.alert("Thank you for visiting! Our team will contact you soon.");
      }
    } catch (error) {
      console.error("Error:", error.message);
      window.alert(error.message);
    }
  };

  /**********************   IMAGE HOVER DESCRIPTION START    ****************************** */
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowFullDescription(false); 
  };

  const handleMoreClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  /************************     IMAGE HOVER DESCRIPTION END     **********************************/

  const handleCloseForm = () => {
    setShowQueryForm(false);
  };

  const shortDescription1="Higher India strategically leverages Oracle Utilities Application Framework (OUAF) to address utility companies' complex needs, providing tailored solutions that optimize operations and enhance customer satisfaction"
  const fullDescription1="Higher India strategically leverages Oracle Utilities Application Framework (OUAF) to address utility companies' complex needs, providing tailored solutions that optimize operations and enhance customer satisfaction. Seamlessly integrating OUAF with existing systems ensures a smooth transition without disrupting daily operations. With our expertise and Oracle's cutting-edge technology, utility businesses can confidently embrace digital transformation, maintain their competitive edge, and optimize operations. Additionally, we offer a suite of Oracle solutions, including IAM, OUD, SOA, WebCenter Portal, and Content."
  const shortDescription2="Higher India implements Active Directory (AD) to centralize and optimize identity and access management within enterprises"
  const fullDescription2="Higher India implements Active Directory (AD) to centralize and optimize identity and access management within enterprises. AD serves as a single directory service, efficiently controlling network-wide user accounts, group memberships, and access rights. By implementing centralized authentication and permission procedures, AD reduces the risk of unauthorized access and security breaches while enhancing security. It also simplifies administration tasks such as group policy enforcement, password management, and user provisioning, increasing operational effectiveness and reducing management overhead."
  const shortDescription3="Firewall implementation and support are critical components of a comprehensive network security strategy."
  const fullDescription3="Firewall implementation and support are critical components of a comprehensive network security strategy. A firewall acts as a barrier between a trusted internal network and untrusted external networks, blocking unauthorized access while allowing legitimate traffic. Our firewall implementation services ensure that your security infrastructure is configured to protect sensitive data, mitigate threats, and prevent cyberattacks. We tailor firewall solutions to your organization's unique requirements, providing ongoing support, including updates, performance monitoring, and incident response"
  const shortDescription4="Network design, implementation, and support form the backbone of a reliable and secure IT infrastructure"
  const fullDescription4="Network design, implementation, and support form the backbone of a reliable and secure IT infrastructure. Our expert team designs customized network solutions tailored to your organization's specific needs, ensuring optimal performance, scalability, and security. From planning and architecture to the seamless implementation of hardware and software components, we create networks that empower your business to operate efficiently. Our ongoing support services include monitoring, troubleshooting, and regular updates to maintain network integrity and performance."
  
  return (
    <div style={{ overflow: 'hidden' }}>
      <Navbar />
      <div className="imple-image-container" >
        <img src={require("./imple.jpg")} alt="img" className="imple-background-image" />
        <div className="imple-overlay-text">Implementation and Support</div>
      </div>

      {/**************   PARRENT   ***********************/}
      <div className="imple-parrent" >
        <div className="imple-child-1" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div>
            <img onClick={() => handleImageClick("Oracle Utilities Application Framework Implementation")} src={require('./framework.svg')} alt="img" />
            <p className="imple-1">Oracle Utilities Application Framework Implementation</p>
          </div>
          {isHovered && (
            <div className="impledesc">
              {showFullDescription ? fullDescription1 : shortDescription1}
              <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                {showFullDescription ? ' Show Less' : '... More'}
              </span>
            </div>
          )}
        </div>


        <div className="imple-child-2" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div>
            <img onClick={() => handleImageClick("Active Directory Implementation")} src={require('./folder.svg')} alt="img" />
            <p className="imple-1">Active Directory Implementation</p>
          </div>
          {isHovered && (
            <div className="impledesc">
              {showFullDescription ? fullDescription2 : shortDescription2}
              <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                {showFullDescription ? ' Show Less' : '... More'}
              </span>
            </div>
          )}
        </div>

        <div className="imple-child-3" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div>
            <img onClick={() => handleImageClick("Firewall Implementation & Support")} src={require('./firewall (1).svg')} alt="img" />
            <p className="imple-1"> Firewall Implementation & Support</p>
          </div>
          {isHovered && (
            <div className="impledesc">
              {showFullDescription ? fullDescription3 : shortDescription3}
              <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                {showFullDescription ? ' Show Less' : '... More'}
              </span>
            </div>
          )}
        </div>

        <div className="imple-child-4" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div>
            <img onClick={() => handleImageClick("Network Design Implementation & Support")} src={require('./wifi.svg')} alt="img" />
            <p className="imple-1"> Network Design Implementation & Support</p>
          </div>
          {isHovered && (
            <div className="impledesc">
              {showFullDescription ? fullDescription4 : shortDescription4}
              <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
                {showFullDescription ? ' Show Less' : '... More'}
              </span>
            </div>
          )}
        </div>
      </div>

      {/****** FOOTER  *********/}
      <Footer />
      <div id="box"></div>
      <div id="play"></div>
      <div id="play1" style={{ position: 'absolute' }}></div>
      <div id="play"></div>
      <div id="play"></div>
      <div id="play"></div>
      <div id="play"></div>




      {/********************************      QUERY FORM        ***************************************** */}
      {showQueryForm && (
        <div className="query-form">
          <form onSubmit={handleSubmit}>
            <button className="button-f2" onClick={handleCloseForm}>
              <img src={require('./remove.svg')} alt="img" /></button>
            <div className='query-p'>
              <h2>Let Me Know More about</h2>
            </div>
            <div className='query-label'>
              <label htmlFor="name">Service Name</label>
              <input type="text" name="name" value={formValues.name} onChange={handleChange} readOnly />
              <label htmlFor="fullName">Full Name</label>
              <input type="text" id="fullName" name="fullName" placeholder="Full Name" value={formValues.fullName} onChange={handleChange} required />
              <label htmlFor="email">E-mail</label>
              <input type="email" name="email" placeholder="Email" value={formValues.email} onChange={handleChange} required />
              <label htmlFor="Phoneno">Phone No</label>
              <input type="tel" name="phone_no" placeholder="9876543210" value={formValues.number} onChange={handleChange} required />
              <label htmlFor="query">Query</label>
              <textarea style={{ width: '340px', height: '150px' }} name="query" placeholder="Your Query" value={formValues.query} onChange={handleChange} required />
            </div>
            <button className='submit-btn' type="submit">Submit</button>
          </form>
        </div>
      )
      }
    </div >
  );
}
export default Implementation;




// import React, { useEffect, useState } from "react";
// import "./Implementation.css";
// import Footer from "./Components/Footer";
// import Navbar from "./Navbar";

// function Implementation() {
//   const [selectedService, setSelectedService] = useState("");
//   const [showQueryForm, setShowQueryForm] = useState(false);
//   const [formValues, setFormValues] = useState({ name: "", phone_no: "", email: "", query: "" });
//   const [isMobile, setIsMobile] = useState(false);
//   const [showFullDescription, setShowFullDescription] = useState(false);

//   /**************TOUCH EVENT*******************/
//   // useEffect(() => {
//   //   const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
//   //   setIsMobile(isTouchDevice);
//   // }, []);

//   /**************   TOUCH EVENT**************/
//   function closeForm() {
//     document.querySelector('.query-form').style.left = '200%';
//   }

//   /************HANDLE TOUCH EVENT **************/
//   const handleTouchStart = () => {
//     setIsHovered(true);
//   };

//   const handleTouchEnd = () => {
//     setIsHovered(false);
//   };
//   /**************************END HERE******************** */

//   const handleImageClick = (serviceName) => {
//     if (showQueryForm && selectedService === serviceName) {
//       setShowQueryForm(false);
//     } else {
//       setSelectedService(serviceName);
//       setShowQueryForm(true);
//       setFormValues({ ...formValues, name: serviceName });
//     }
//   };

//   const handleChange = (e) => {
//     setFormValues({ ...formValues, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://highersystem-backend-1.onrender.com/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formValues),
//       });
//       if (response.ok) {
//         window.alert("Message has been sent!");
//         window.alert("Thank you for visiting! Our team will contact you soon.");
//       }
//     } catch (error) {
//       console.error("Error:", error.message);
//       window.alert(error.message);
//     }
//   };

//   /**********************   IMAGE HOVER DESCRIPTION START    ****************************** */
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     if (!isMobile) {
//       setIsHovered(true);
//     }
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//     setShowFullDescription(false);
//   };

//   /************************     IMAGE HOVER DESCRIPTION END     **********************************/

//   const handleMoreClick = () => {
//     setShowFullDescription(!showFullDescription);
//   };

//   const handleCloseForm = () => {
//     setShowQueryForm(false);
//   };

//   //Line 1
//   const shortDescription1 = "Higher India strategically leverages Oracle Utilities Application Framework (OUAF) to address utility companies' complex needs, providing tailored solutions that optimize operations and enhance customer satisfaction"
//   const fullDescription1 = "Higher India strategically leverages Oracle Utilities Application Framework (OUAF) to address utility companies' complex needs, providing tailored solutions that optimize operations and enhance customer satisfaction. Seamlessly integrating OUAF with existing systems ensures a smooth transition without disrupting daily operations. With our expertise and Oracle's cutting-edge technology, utility businesses can confidently embrace digital transformation, maintain their competitive edge, and optimize operations. Additionally, we offer a suite of Oracle solutions, including IAM, OUD, SOA, WebCenter Portal, and Content."
//   const shortDescription2 = ""
//   const fullDescription2 = ""
//   const shortDescription3 = ""
//   const fullDescription3 = ""
//   const shortDescription4 = ""
//   const fullDescription4 = ""

//   return (
//     <div style={{ overflow: 'hidden' }}>
//       <Navbar />
//       <div className="imple-image-container" >
//         <img src={require("./imple.jpg")} alt="img" className="imple-background-image" />
//         <div className="imple-overlay-text">Implementation and Support</div>
//       </div>

//       {/**************   PARRENT   ***********************/}
//       <div className="imple-parrent" >
//         <div className="imple-child-1">
//           <div>
//             <img onClick={() => handleImageClick("Oracle Utilities Application Framework Implementation")} src={require('./framework.svg')} alt="img" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave} />
//             <p className="imple-1">Oracle Utilities Application Framework Implementation</p>
//           </div>
//           {isHovered && (
//             <div className="impledesc">
//               {showFullDescription ? fullDescription1 : shortDescription1}
//               <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
//                 {showFullDescription ? ' Show Less' : '... More'}
//               </span>
//             </div>
//           )}
//         </div>


//         <div className="imple-child-2" >
//           <div>
//             <img onClick={() => handleImageClick("Active Directory Implementation")} src={require('./folder.svg')} alt="img" onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave} />
//             <p className="imple-1">Active Directory Implementation</p>
//           </div>
//           {isHovered && (
//             <div className="impledesc">
//               {showFullDescription ? fullDescription2 : shortDescription2}
//               <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
//                 {showFullDescription ? ' Show Less' : '... More'}
//               </span>
//             </div>
//           )}
//         </div>

//         <div className="imple-child-3" >
//           <div>
//             <img onClick={() => handleImageClick("Firewall Implementation & Support")} src={require('./firewall (1).svg')} alt="img" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
//             <p className="imple-1"> Firewall Implementation & Support</p>
//           </div>
//           {isHovered && (
//             <div className="impledesc">
//               {showFullDescription ? fullDescription3 : shortDescription3}
//               <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
//                 {showFullDescription ? ' Show Less' : '... More'}
//               </span>
//             </div>
//           )}
//         </div>

//         <div className="imple-child-4" >
//           <div>
//             <img onClick={() => handleImageClick("Network Design Implementation & Support")} src={require('./wifi.svg')} alt="img" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
//             <p className="imple-1"> Network Design Implementation & Support</p>
//           </div>
//           {isHovered && (
//             <div className="impledesc">
//               {showFullDescription ? fullDescription4 : shortDescription4}
//               <span className="more-link" style={{ color: 'blue', cursor: 'pointer' }} onClick={handleMoreClick}>
//                 {showFullDescription ? ' Show Less' : '... More'}
//               </span>
//             </div>
//           )}
//         </div>
//       </div>




//       {/****** FOOTER  *********/}
//       <Footer />
//       <div id="box"></div>
//       <div id="play"></div>
//       <div id="play1" style={{ position: 'absolute' }}></div>
//       <div id="play"></div>
//       <div id="play"></div>
//       <div id="play"></div>
//       <div id="play"></div>

//       {/********************************      QUERY FORM        ***************************************** */}
//       {showQueryForm && (
//         <div className="query-form">
//           <form onSubmit={handleSubmit}>
//             <button className="button-f2" onClick={handleCloseForm}>
//               <img src={require('./remove.svg')} alt="img" /></button>
//             <div className='query-p'>
//               <h2>Let Me Know More about</h2>
//             </div>
//             <div className='query-label'>
//               <label htmlFor="name">Service Name</label>
//               <input type="text" name="name" value={formValues.name} onChange={handleChange} readOnly />
//               <label htmlFor="fullName">Full Name</label>
//               <input type="text" id="fullName" name="fullName" placeholder="Full Name" value={formValues.fullName} onChange={handleChange} required />
//               <label htmlFor="email">E-mail</label>
//               <input type="email" name="email" placeholder="Email" value={formValues.email} onChange={handleChange} required />
//               <label htmlFor="Phoneno">Phone No</label>
//               <input type="tel" name="phone_no" placeholder="9876543210" value={formValues.number} onChange={handleChange} required />
//               <label htmlFor="query">Query</label>
//               <textarea style={{ width: '340px', height: '150px' }} name="query" placeholder="Your Query" value={formValues.query} onChange={handleChange} required />
//             </div>
//             <button className='submit-btn' type="submit">Submit</button>
//           </form>
//         </div>
//       )
//       }
//     </div >
//   );
// }
// export default Implementation;